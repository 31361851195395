<!--
 * @Description: 关于我们页面组件，未完成
 -->
<template>
	<div class="login">
		<div class="log-box">
			<div class="log-tit">用户注册</div>
			<el-divider></el-divider>
			<el-form
				:model="RegisterUser"
				:rules="rules"
				ref="ruleForm"
				class="loginform"
				label-width="80px"
			>
				<el-form-item prop="username">
					<el-input
						v-model="RegisterUser.username"
						placeholder="请输入邮箱或手机号"
					></el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input
						v-model="RegisterUser.password"
						type="password"
						show-password
						placeholder="请输入密码"
					></el-input>
				</el-form-item>
				<el-form-item prop="password2">
					<el-input
						v-model="RegisterUser.password2"
						type="password"
						show-password
						placeholder="确认密码"
					></el-input>
				</el-form-item>
			</el-form>
			<div class="xiyi">
				<el-checkbox v-model="checked"
					>请勾选同意
					<span style="color: #ce3720"
						>《先谱特电商用户注册协议和隐私政策》</span
					></el-checkbox
				>
			</div>
			<div class="btn" @click="register">立即注册</div>
		</div>
	</div>
</template>
<script>
import { registerApi } from "../../api/user.js";
export default {
	components: {},
	data() {
		// 用户名的校验方法
		let validateName = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("请输入用户名"));
			}
			// 用户名以字母开头,长度在5-16之间,允许字母数字下划线
			// const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
			// if (userNameRule.test(value)) {
			//判断数据库中是否已经存在该用户名
			// this.$axios
			//   .post("/api/users/findUserName", {
			//     userName: this.RegisterUser.name
			//   })
			//   .then(res => {
			//     // “001”代表用户名不存在，可以注册
			//     if (res.data.code == "001") {
			this.$refs.ruleForm.validateField("checkPass");
			return callback();
			//     } else {
			//       return callback(new Error(res.data.msg));
			//     }
			//   })
			//   .catch(err => {
			//     return Promise.reject(err);
			//   });
			// } else {
			//   return callback(new Error("字母开头,长度5-16之间,允许字母数字下划线"));
			// }
		};
		// 密码的校验方法
		let validatePass = (rule, value, callback) => {
			if (value === "") {
				return callback(new Error("请输入密码"));
			}
			// 密码以字母开头,长度在6-18之间,允许字母数字和下划线
			// const passwordRule = /^[a-zA-Z]\w{5,17}$/;
			// if (passwordRule.test(value)) {
			this.$refs.ruleForm.validateField("checkPass");
			return callback();
			// } else {
			//   return callback(
			//     new Error("字母开头,长度6-18之间,允许字母数字和下划线")
			//   );
			// }
		};
		// 确认密码的校验方法
		let validateConfirmPass = (rule, value, callback) => {
			if (value === "") {
				return callback(new Error("请输入确认密码"));
			}
			// 校验是否以密码一致
			if (
				this.RegisterUser.password != "" &&
				value === this.RegisterUser.password
			) {
				this.$refs.ruleForm.validateField("checkPass");
				return callback();
			} else {
				return callback(new Error("两次输入的密码不一致"));
			}
		};
		return {
			RegisterUser: {
				username: "",
				password: "",
				password2: "",
			},
			checked: false,
			// 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
			rules: {
				username: [{ validator: validateName, trigger: "blur" }],
				password: [{ validator: validatePass, trigger: "blur" }],
				password2: [{ validator: validateConfirmPass, trigger: "blur" }],
			},
		};
	},
	methods: {
		register() {
			let data = this.RegisterUser;
			this.$refs["ruleForm"].validate((valid) => {
				//如果通过校验开始注册
				if (valid) {
					if (this.checked) {
						registerApi(data)
							.then((res) => {
								// 弹出通知框提示注册成功信息
								this.$message.success(res.msg);
								this.$router.push("/user/login");
							})
							.catch((err) => {
								this.$message.error(err.data.msg);
								return Promise.reject(err);
							});
					} else {
						this.$message.error("请阅读并同意隐私政策");
					}
				} else {
					return false;
				}
			});
		},
	},
};
</script>
<style>
.loginform .el-form-item__content {
	margin-left: 0 !important;
}
.loginform .el-input__inner {
	height: 48px !important;
	font-size: 21px !important;
}
</style>
<style scoped>
.login {
	padding-top: 100px;
	width: 100%;
	height: 700px;
	background-image: url("../../assets/index/bglogin.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: top left;
}

.log-box {
	padding: 30px;
	margin: 0 auto 0;
	width: 403px;
	background: #ffffff;
	border-radius: 26px 26px 26px 26px;
}
.log-tit {
	position: relative;
	height: 36px;
	font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
	font-weight: 500;
	font-size: 20px;
	color: #070707;
	line-height: 38px;
	text-align: left;
	font-style: normal;
	text-transform: none;
}
.log-tit::after {
	content: "";
	display: block;
	position: absolute;
	bottom: -24px;
	left: 8px;
	width: 86px;
	height: 8px;
	background: #ce3720;
	border-radius: 3px 3px 3px 3px;
}
.btn {
	margin: 0 auto;
	height: 46px;
	background: #ce3720;
	border-radius: 5px 5px 5px 5px;
	text-align: center;
	line-height: 46px;
	font-weight: 400;
	font-size: 24px;
	color: #ffffff;
	letter-spacing: 6px;
	cursor: pointer;
}
.xiyi {
	font-size: 19px;
	margin: 20px 0;
}
</style>
